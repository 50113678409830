import { useIndexStore } from '~/stores/index'

type User = {
  id: number
  name: string
  email: string
  email_verified_at: string
  created_at: string
  updated_at: string
  phone: string
  phone_verified_at: string
  birthdate: string
  wallet_balance: number
  bonus_card_number: number | string
  birthday_wallet_balance: number
  lang: string
  city_id: number
  verified: boolean
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): {
    user: User | null
    token: string | null | undefined
    loggedIn: boolean
  } => ({
    user: null,
    token: useCookie('token') ? useCookie('token').value : null,
    loggedIn: false,
  }),
  actions: {
    fetchUser() {
      return useApi()
        .$get('auth/user')
        .then((response) => {
          this.setUser(response)
        })
        .catch(async (error) => {
          const indexStore = useIndexStore()
          const authStore = useAuthStore()
          const localePath = useLocalePath()

          if (error.status === 401) {
            await authStore.logout()
            await navigateTo(localePath(`/${indexStore.current_county?.slug}`))
            window.location.reload()
          }
        })
    },
    updateUser(data: { email: string; birthdate: string }) {
      return useApi().$post('auth/update', { body: data })
    },
    async getUserCode(params) {
      await useApi().$post(`/auth/send_confirmation`, { body: params })
    },
    checkOtp(payload) {
      return new Promise((resolve, reject) => {
        useApi()
          .$post<{ access_token: string; user: User }>(`/auth/code/login`, {
            body: payload,
          })
          .then((res) => {
            if (res.access_token) {
              this.setToken(res.access_token)
              this.setUser(res.user)
              resolve(res)
            } else {
              reject(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    signUp(payload) {
      useApi().$post('/auth/send_confirmation', { body: payload })
    },
    setToken(token: string) {
      useCookie('token').value = token
      this.token = token
    },
    setUser(user: User) {
      this.loggedIn = true
      localStorage.setItem('user', JSON.stringify(user))
      this.user = user
    },
    setInitialState() {
      if (process.client) {
        const user = JSON.parse(localStorage.getItem('user') || 'null')
        const token = JSON.parse(localStorage.getItem('token') || 'null')
        this.user = user && Object.keys(user).length !== 0 ? user : null
        this.token = token
      }
    },
    logout() {
      useApi().$post('/auth/logout')
      localStorage.setItem('user', JSON.stringify({}))
      useCookie('token').value = undefined
    },
  },
})
